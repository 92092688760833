<template>
  <div>
    <!-- Modal Wrapper - Controlled by 'modale' prop -->
    <v-dialog
      v-if="modale"
      v-model="showForm"
      max-width="1000px"
      persistent
      transition="dialog-top-transition"
      @keydown.esc="close"
    >
      <template v-slot:default>
        <FormContent
          :item="item"
          :agences="agences"
          :cheques_dispo="cheques_dispo"
          :modale="modale"
          @close="close"
          @change="change"
        />
      </template>
    </v-dialog>

    <!-- Inline Form -->
    <FormContent
      :item="item"
      :agences="agences"
      :cheques_dispo="cheques_dispo"
      :modale="modale"
      @close="close"
      @change="change"
      v-else
    />
  </div>
</template>

<script>
import FormContent from "../components/RemiseContent.vue";

export default {
  components: {
    FormContent,
  },
  name: "remiseform",
  props: {
    item: Object,
    showForm: Boolean,
    agences: Array,
    cheques_dispo: Array,
    modale: { type: Boolean, default: true },
  },
  data: () => ({}),

  computed: {},

  watch: {},

  created() {},
  mounted() {},

  methods: {
    async close() {
      this.$emit("close");
    },
    async change(item) {
      this.$emit("change", item);
    },
  },
};
</script>
