<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card :loading="progress" flat>
        <v-toolbar flat>
          <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                v-if="
                  editedItem.id > 0 &&
                  ($store.state.auth.includes('01061') || $store.state.isadmin)
                "
                @click="show_attached"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon> mdi-paperclip </v-icon>
              </v-btn>
            </template>
            <span>Documents Attachés</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                @click="generatePdf(true)"
                v-if="editedItem.id > 0 && cheques.length > 0"
                v-bind="attrs"
                v-on="on"
                ><v-icon> mdi-printer </v-icon></v-btn
              >
            </template>
            <span>Imprimer la remise </span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                @click="save(0)"
                v-if="editedItem.closed == 0"
                :disabled="change == 1"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon> mdi-file-document-multiple-outline</v-icon>
              </v-btn>
            </template>
            <span>Enregistrer La remise </span>
          </v-tooltip>

          <v-tooltip bottom :key="change">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                @click="save(1)"
                v-if="editedItem.closed == 0 && cheques.length > 0"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon> mdi-file-document-check </v-icon>
              </v-btn>
            </template>
            <span>Valider La remise </span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                @click.stop="close"
                v-if="modale || editedItem.id < 0"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="red"> mdi-close-box-outline </v-icon>
              </v-btn>
            </template>
            <span>Fermer le document </span>
          </v-tooltip>
        </v-toolbar>
        <v-divider class="mb-4"></v-divider>
        <v-card-text class="pa-0">
          <v-container>
            <v-row dense>
              <v-col cols="6"></v-col>
              <v-col cols="4">
                <v-select
                  dense
                  :items="agences"
                  item-text="compte_agence"
                  item-value="id"
                  v-model="editedItem.agence_id"
                  label="Compte de remise "
                  :readonly="item.closed == 1"
                  @change="change++"
                  :rules="[(v) => !!v || 'Agence obligatoire']"
                >
                </v-select>
              </v-col>

              <v-col cols="2">
                <datepicker
                  label="Date"
                  v-model="editedItem.date_remise"
                  :edit="
                    editedItem.id < 0 || (editedItem.id > 0 && item.closed == 0)
                  "
                  :clearable="false"
                  @change="change++"
                  :key="cs"
                ></datepicker>
              </v-col>

              <!-- <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    autocomplete="off"
                    type="text"
                    dense
                    v-model="editedItem.comment"
                    :readonly="item.closed == 1"
                    label="Comment"
                    @change="change++"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col> -->
            </v-row>

            <v-row dense no-gutters>
              <v-col cols="12" sm="6" md="6" v-if="editedItem.closed == 0">
                <v-card class="px-2 mr-2" outlined>
                  <v-card-title class="d-flex align-center">
                    <v-text-field
                      v-model="searchQuery"
                      label="Rechercher un chèque..."
                      dense
                      outlined
                      clearable
                      prepend-inner-icon="mdi-magnify"
                      class="flex-grow-1"
                    />
                  </v-card-title>

                  <v-container
                    style="height: 318px; overflow-y: auto"
                    class="mt-n8"
                  >
                    <div v-if="filteredCheques.length > 0">
                      <v-row
                        dense
                        no-gutters
                        v-for="cheque in filteredCheques"
                        :key="cheque.id"
                      >
                        <v-col cols="12">
                          <v-alert
                            light
                            dense
                            color="teal lighten-5"
                            border="left"
                            elevation="2"
                            class="my-1"
                          >
                            <v-row no-gutters align="center">
                              <v-col cols="10">
                                <div>
                                  <span class="font-weight-bold">
                                    {{ cheque.tier_name }} -
                                    {{
                                      numberWithSpace(cheque.montant.toFixed(2))
                                    }}
                                    DA
                                  </span>
                                </div>
                                <div>
                                  <span class="text-caption">
                                    {{ cheque.bank }} - {{ cheque.nocheque }} du
                                    :
                                    {{ cheque.date_cheque }}
                                  </span>
                                </div>
                              </v-col>
                              <v-col cols="2" class="text-right">
                                <v-icon @click="add_cheque(cheque)"
                                  >mdi-arrow-right-thick</v-icon
                                >
                              </v-col>
                            </v-row>
                          </v-alert>
                        </v-col>
                      </v-row>
                    </div>
                    <div v-else class="text-center text-caption pa-4">
                      Aucun chèque trouvé.
                    </div>
                  </v-container>
                  <v-divider class="mt-2" />

                  <!-- Résumé en bas -->
                  <v-card-actions class="justify-space-between pa-2">
                    <span class="font-weight-bold">
                      {{ totalCheques }} chèques disponibles</span
                    >
                    <span class="font-weight-bold"
                      >Montant Total :
                      {{ numberWithSpace(totalDispo.toFixed(2)) }} DA</span
                    >
                  </v-card-actions>
                </v-card>
              </v-col>

              <v-col
                cols="12"
                :sm="editedItem.closed == 0 ? 6 : 12"
                :md="editedItem.closed == 0 ? 6 : 12"
              >
                <v-card outlined>
                  <v-card-title class="justify-space-between">
                    <span class="font-weight-bold"
                      >Total : {{ cheques.length }} chèques</span
                    >
                    <span class="font-weight-bold"
                      >Somme :
                      {{ numberWithSpace(totalMontant.toFixed(2)) }} DA</span
                    >
                  </v-card-title>

                  <v-divider />

                  <!-- Liste des chèques avec scroll -->
                  <v-container
                    class="mt-2"
                    style="max-height: 318px; overflow-y: auto"
                  >
                    <div v-if="cheques.length > 0">
                      <v-row
                        dense
                        no-gutters
                        v-for="cheque in cheques"
                        :key="cheque.id"
                      >
                        <v-col cols="12">
                          <v-alert
                            light
                            dense
                            color="light-green accent-2"
                            border="left"
                            elevation="2"
                            class="my-1"
                          >
                            <v-row no-gutters align="center">
                              <v-col cols="10">
                                <div>
                                  <span class="font-weight-bold">
                                    {{ cheque.tier_name }} -
                                    {{
                                      numberWithSpace(cheque.montant.toFixed(2))
                                    }}
                                    DA
                                  </span>
                                </div>
                                <div>
                                  <span class="text-caption">
                                    {{ cheque.bank }} - {{ cheque.nocheque }} du
                                    :
                                    {{ cheque.date_cheque }}
                                  </span>
                                </div>
                              </v-col>
                              <v-col cols="2" class="text-right">
                                <v-icon
                                  v-if="editedItem.closed == 0"
                                  color="red"
                                  @click="delchips(cheque)"
                                  >mdi-close-circle</v-icon
                                >
                              </v-col>
                            </v-row>
                          </v-alert>
                        </v-col>
                      </v-row>
                    </div>
                    <div v-else class="text-center text-caption pa-4">
                      Aucun chèque sélectionné.
                    </div>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-form>
    <component
      :is="!isDocListClose ? 'filelist' : null"
      :item="item"
      :showform="!isDocListClose"
      :key="fdocl"
      @close="isDocListClose = true"
      :editer="true"
      :doc_type="56"
      :auth="auth"
    >
    </component>
    <!-- PDF Preview Dialog -->
    <v-dialog v-model="pdfDialog" max-width="900px">
      <v-card>
        <v-card-title>Document Preview</v-card-title>
        <v-card-text>
          <iframe
            :src="pdfUrl + '#toolbar=0'"
            width="100%"
            height="600px"
          ></iframe>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="generatePdf(false)">Print</v-btn>
          <v-btn color="error" @click="pdfDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <template>
      <confirmdialog ref="confirm" />
    </template>
  </div>
</template>

<script>
import UPSERT_REMISE from "../graphql/Compta/UPSERT_REMISE.gql";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { myName, myadress } from "print/data.js";
import { datefr } from "@/utils/functions.js";
export default {
  components: {
    confirmdialog: () => import("./ConfirmDialog.vue"),
    datepicker: () => import("./DatePicker.vue"),
    filelist: () => import("../components/FileList.vue"),
  },
  name: "remiseform",
  props: {
    item: Object,
    agences: Array,
    cheques_dispo: Array,
    modale: { type: Boolean, default: true },
  },
  data: () => ({
    searchQuery: "",
    progress: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    cs: 1456,
    kq: 100,
    change: 0,
    cheques_selected: [],
    cheques_list: [],
    defaultItem: { id: -1, closed: 0, cheques: [] },
    remise: null,
    editedItem: {},
    cheques: [],
    pdfDialog: false,
    pdfUrl: "",
    isDocListClose: true,
    fdocl: 2584,
  }),

  computed: {
    auth() {
      let a = "01116";
      return a;
    },
    totalMontant() {
      return this.cheques.reduce((sum, cheque) => sum + cheque.montant, 0);
    },
    filteredCheques() {
      if (!this.searchQuery) {
        return this.cheques_list;
      }
      const query = this.searchQuery.toLowerCase();
      return this.cheques_list.filter((cheque) => {
        return (
          cheque.tier_name.toLowerCase().includes(query) ||
          cheque.bank.toLowerCase().includes(query) ||
          cheque.nocheque.toLowerCase().includes(query) ||
          cheque.date_cheque.toLowerCase().includes(query) ||
          cheque.montant.toFixed(2).toString().includes(query)
        );
      });
    },

    totalCheques() {
      return this.filteredCheques.length;
    },
    totalDispo() {
      return this.filteredCheques.reduce(
        (sum, cheque) => sum + cheque.montant,
        0
      );
    },
    nombre() {
      return this.cheques.length;
    },

    total() {
      return this.cheques.reduce((a, b) => a + (b["montant"] || 0), 0);
    },

    formTitle() {
      if (this.editedItem)
        return this.editedItem.id < 0
          ? "Nouvelle Remise "
          : "Remise N° " + this.editedItem.ref;
      else return "";
    },
  },

  watch: {},

  created() {},
  mounted() {
    if (this.item) {
      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);
        this.cheques = Object.assign([], this.item.cheques);
        this.cs++;
      } else {
        this.editedItem = Object.assign({}, this.defaultItem);
        let i = this.agences.findIndex((elm) => elm.default_id == 1);
        if (i >= 0) this.editedItem.agence_id = this.agences[i].id;
        this.editedItem.date_remise = this.$store.state.today;
        this.cs++;
      }
    }
    this.cheques_list = this.cheques_dispo.filter(
      (elm) => !this.cheques.map((elm) => elm.id).includes(elm.id)
    );
  },

  methods: {
    show_attached() {
      this.isDocListClose = false;
      this.fdocl++;
    },
    delchips(item) {
      let i = this.cheques.findIndex((elm) => elm.id == item.id);
      if (i >= 0) {
        this.cheques_list.push(this.cheques[i]);
        this.cheques.splice(i, 1);
        this.editedItem.montant = this.total;
        this.editedItem.nb_cheques = this.nombre;
      }
      this.change++;
    },
    generatePdf(withImage) {
      this.remise = this.editedItem;
      if (!this.remise.bank) return;

      let imagePath;
      try {
        imagePath = require("@/assets/remises/" +
          this.remise.bank.toLowerCase() +
          ".jpg");
      } catch (error) {
        this.snackbar_text =
          "Document de remise de la banque " +
          this.remise.bank +
          " introuvable";
        this.snackbar_color = "error";
        this.snackbar = true;
        return;
      }

      const img = new Image();
      img.src = imagePath;

      // Bank-specific configuration
      const bankConfigs = {
        agb: {
          widthMm: 220, // Real width in mm
          heightMm: 150, // Real height in mm
          chequeStartY: 50,
          chequeStartX: 8,
          cellPadding: 0,
          headers: [
            {
              value: "X",
              X: 53,
              Y: 33,
              fontSize: 20,
            },
            {
              value: myName,
              X: 173,
              Y: 55,
              fontSize: 10,
            },
            {
              value: myadress,
              X: 170,
              Y: 75,
              W: 45,
              fontSize: 10,
            },
            {
              value: this.remise.compte,
              X: 170,
              Y: 100,
              fontSize: 12,
            },
            {
              value: this.remise.nb_cheques,
              X: 52,
              Y: 121,
              fontSize: 12,
              dec: 0,
            },
            {
              value: this.remise.montant,
              X: 135,
              Y: 121,
              fontSize: 10,
              dec: 2,
            },
            {
              value: this.remise.date_remise,
              X: 35,
              Y: 135,
              fontSize: 12,
              type: "date",
            },
          ],
          listheader: [
            { value: "bank", align: "left", cellWidth: 29, fontSize: 10 },
            {
              value: "agence_ville",
              align: "left",
              cellWidth: 33,
              fontSize: 10,
            },
            { value: "tier_name", align: "left", cellWidth: 37, fontSize: 10 },
            { value: "nocheque", align: "center", cellWidth: 23, fontSize: 10 },
            {
              value: "montant",
              align: "right",
              slot: "cur",
              cellWidth: 30,
              fontSize: 10,
            },
          ],
        },
        bea: {
          widthMm: 135, // Real width in mm
          heightMm: 210, // Real height in mm

          chequeStartY: 80,
          chequeStartX: 12,
          cellPadding: 2,

          headers: [
            {
              value: myName,
              X: 60,
              Y: 35,
              fontSize: 10,
            },
            {
              value: myadress,
              X: 60,
              Y: 40,
              W: 100,
              fontSize: 10,
            },
            {
              value: this.remise.compte,
              X: 60,
              Y: 28,
              fontSize: 12,
            },
            {
              value: this.remise.date_remise,
              X: 90,
              Y: 11,
              fontSize: 12,
              type: "date",
            },
            {
              value: this.remise.montant,
              X: 100,
              Y: 179,
              fontSize: 12,
              dec: 2,
            },
            {
              value: this.$lettre.NumberToLetter(this.remise.montant),
              X: 10,
              Y: 179,
              W: 85,
              fontSize: 10,
              factor: 1.5,
            },
          ],
          listheader: [
            { value: "tier_name", align: "left", cellWidth: 38, fontSize: 9 },
            {
              value: "agence_ville",
              align: "left",
              cellWidth: 25,
              fontSize: 9,
            },
            { value: "nocheque", align: "center", cellWidth: 15, fontSize: 9 },
            {
              value: "montant",
              align: "center",
              slot: "cur",
              cellWidth: 50,
              fontSize: 11,
            },
          ],
        },
        default: {
          widthMm: 297.2, // Real width in mm
          heightMm: 210, // Real height in mm
          chequeStartY: 82,
          chequeStartX: 38,
          cellPadding: 0,
          headers: [
            {
              value: "X",
              X: 83,
              Y: 63,
              fontSize: 24,
            },
            {
              value: myName,
              X: 210,
              Y: 85,
              fontSize: 10,
            },
            {
              value: myadress,
              X: 203,
              Y: 115,
              W: 50,
              fontSize: 10,
            },
            {
              value: this.remise.nb_cheques,
              X: 80,
              Y: 155,
              fontSize: 12,
              dec: 0,
            },
            {
              value: this.remise.compte,
              X: 201,
              Y: 150,
              fontSize: 12,
            },
            {
              value: this.remise.date_remise,
              X: 60,
              Y: 168,
              fontSize: 12,
            },
            {
              value: this.remise.montant,
              X: 167,
              Y: 155,
              fontSize: 12,
              dec: 2,
            },
          ],
          listheader: [
            { value: "bank", align: "left", cellWidth: 30, fontSize: 12 },
            {
              value: "agence_ville",
              align: "left",
              cellWidth: 33,
              fontSize: 12,
            },
            { value: "tier_name", align: "left", cellWidth: 35, fontSize: 10 },
            { value: "nocheque", align: "center", cellWidth: 29, fontSize: 12 },
            {
              value: "montant",
              align: "right",
              slot: "cur",
              cellWidth: 30,
              fontSize: 12,
            },
          ],
        },
      };

      const bankKey = this.remise.bank.toLowerCase();
      const config = bankConfigs[bankKey] || bankConfigs.default;

      img.onload = () => {
        const pdfWidthMm = config.widthMm;
        const pdfHeightMm = config.heightMm;
        const isLandscape = pdfWidthMm > pdfHeightMm;
        let doc;
        let offsetX = 0;
        let offsetY = 0;
        const a4Width = 210; // A4 width in mm
        const a4Hight = 297; // A4 width in mm

        // Create jsPDF document with mm units
        if (withImage) {
          doc = new jsPDF({
            orientation: isLandscape ? "landscape" : "portrait",
            unit: "mm",
            format: [pdfWidthMm, pdfHeightMm],
          });
        } else {
          doc = new jsPDF({
            orientation: isLandscape ? "landscape" : "portrait",
            unit: "mm",
            format: "a4",
          });
          if (isLandscape) {
            offsetX = a4Hight - pdfWidthMm;
            offsetY = (a4Width - pdfHeightMm) / 2;
          } else {
            offsetX = (a4Width - pdfWidthMm) / 2;
            offsetY = 0;
          }
        }
        // Add background image if previewing
        if (withImage) {
          doc.addImage(img, "JPEG", offsetX, offsetY, pdfWidthMm, pdfHeightMm);
        }
        doc.setFont("helvetica", "normal");
        doc.setTextColor(0, 0, 0);
        //headers
        config.headers.forEach((element) => {
          doc.setFontSize(element.fontSize);
          if (element.factor) {
            doc.setLineHeightFactor(element.factor);
          }
          if (element.W) {
            const var_Lines = doc.splitTextToSize(element.value, element.W);
            doc.text(var_Lines, element.X + offsetX, element.Y + offsetY);
          } else if (element.dec)
            doc.text(
              this.numberWithSpace(element.value.toFixed(element.dec)),
              element.X + offsetX,
              element.Y + offsetY
            );
          else if (element.type == "date")
            doc.text(
              datefr(element.value),
              element.X + offsetX,
              element.Y + offsetY
            );
          else
            doc.text(
              element.value.toString(),
              element.X + offsetX,
              element.Y + offsetY
            );
        });

        //data formating
        let items = [];
        let item = {};

        for (let index = 0; index < this.remise.cheques.length; index++) {
          item = {};
          config.listheader.forEach((elm) => {
            if (elm.value == "no") {
              item[elm.value] = index + 1;
            }
            if (elm.slot == "cur" && this.remise.cheques[index][elm.value]) {
              item[elm.value] = this.numberWithSpace(
                parseFloat(this.remise.cheques[index][elm.value]).toFixed(2)
              );
            } else if (this.remise.cheques[index][elm.value])
              item[elm.value] = this.remise.cheques[index][elm.value];
          });
          items.push(item);
        }

        let columns = [];
        let columnStyles = {};
        config.listheader.forEach((element) => {
          columns.push({ dataKey: element.value });
          columnStyles[element.value] = {
            valign: "top",
            halign: element.align,
            cellWidth: element.cellWidth,
            fontSize: element.fontSize,
          };
        });
        doc.autoTable({
          margin: {
            left: config.chequeStartX + offsetX,
            top: config.chequeStartY + offsetY,
          },
          body: items,
          columnStyles: columnStyles,
          columns: columns,
          theme: "plain",
          styles: {
            valign: "middle",
            fillStyle: "transparent", // Ensure no fill
            textColor: [0, 0, 0],
            cellPadding: {
              top: config.cellPadding,
              right: 0,
              bottom: config.cellPadding,
              left: 0,
            },
            lineHeightFactor: 1.5,
          },
          tableWidth: "auto",
        });

        // Preview or Print
        if (withImage) {
          doc.setProperties({ title: "Remise" });
          this.pdfUrl = doc.output("bloburl"); // For dialog preview
          this.pdfDialog = true; // Open dialog
        } else {
          doc.autoPrint(); // Direct print
          window.open(doc.output("bloburl")); // Fallback
        }
      };
    },

    async close() {
      let ok = true;
      if (this.change > 1) {
        ok = false;
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Modification encours!! - Etês-vous sûr de vouloir quitter?"
          )
        ) {
          ok = true;
        }
      }
      if (ok) this.$emit("close");
    },
    async maj(query, v) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },
    add_cheque(item) {
      let i = this.cheques_list.findIndex((elm) => elm.id == item.id);
      if (i >= 0) {
        this.cheques.push(this.cheques_list[i]);
        this.cheques_list.splice(i, 1);
      }
      this.cheques_selected = [];
      this.editedItem.montant = this.total;
      this.editedItem.nb_cheques = this.nombre;
      this.change++;
    },
    async save(closed) {
      let ok = true;
      if (this.$refs.form.validate()) {
        let i = this.agences.findIndex(
          (elm) => elm.id == this.editedItem.agence_id
        );
        if (i >= 0) this.editedItem.compte = this.agences[i].compte;
        if (closed == 1 && this.editedItem.id > 0) {
          ok = false;
          if (
            await this.$refs.confirm.open(
              "Confirmation",
              "Etês-vous sûr de vouloir cloturer cette remise?"
            )
          ) {
            ok = true;
          }
        }
        if (ok) {
          this.progress = true;
          let v = {
            input: [
              {
                id: this.editedItem.id > 0 ? this.editedItem.id : null,
                agence_id: this.editedItem.agence_id,
                closed: closed,
                comment: this.editedItem.comment,
                date_remise: this.editedItem.date_remise,
                cheques:
                  this.cheques.length > 0
                    ? this.cheques.map((elm) => elm.id)
                    : null,
              },
            ],
          };
          let r = await this.maj(UPSERT_REMISE, v, true);
          if (r) {
            if (this.editedItem.id < 0) {
              this.editedItem.id = r.UpsertRemise[0].id;
              this.editedItem.ref = r.UpsertRemise[0].ref;
              this.editedItem.bank = r.UpsertRemise[0].bank;
              this.editedItem.created_at = r.UpsertRemise[0].created_at;
              this.editedItem.status_name = r.UpsertRemise[0].status_name;
            }
            this.editedItem.closed = closed;
            this.editedItem.cheques = this.cheques;
            this.progress = false;
            this.change = 1;
            this.$emit("change", this.editedItem);
            this.$store.dispatch("Changed", true);
          }
        }
      }
    },
  },
};
</script>
